@import "reactjs-popup/dist/index.css";
@import 'react-toastify/dist/ReactToastify.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    @apply font-raleway;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @apply font-dmSerifDisplay;
}

.nav-link {
    &.active {
        @apply border-cred-100 text-cred-100;
    }
}
.mob-nav-link, .dropdown-nav-link {
    &.active {
        @apply text-cred-100;
    }
}

.popup-content {
    min-width: 100%;
    border-radius: 10px;
    @media screen and (min-width: 768px) {
        min-width: 70%;
    }
}

.custom-pagination {
    @apply flex items-center justify-center gap-2 text-sm mt-8;
    .page-number {
        @apply px-3 py-2 bg-gray-200 hover:bg-cred-100 hover:text-white cursor-pointer rounded;
        &.active {
            @apply bg-cred-100 text-white;
        }
    }
    .next-prev, .break {
        @apply text-base px-3 py-2 bg-gray-200 rounded;
    }
    .disabled {
        @apply cursor-auto text-gray-500;
    }
}

.blog-content {
    h1 {
        @apply text-5xl font-semibold;
    }
    h2 {
        @apply text-4xl font-semibold;
    }
    h3 {
        @apply text-3xl font-semibold;
    }
    h4 {
        @apply text-2xl font-semibold;
    }
    h5 {
        @apply text-xl font-semibold;
    }
    h6 {
        @apply text-lg font-semibold;
    }
    p {
        @apply text-base text-gray-600;
    }
    a {
        @apply text-cred-100 underline;
    }
    ul {
        @apply list-disc ml-4;
    }
    ol {
        @apply list-decimal ml-4;
    }
}

.testimonial-card {
    &:hover {
        p {
            @apply text-white;
        }
    }
}

.member-card {
    &:hover {
        h3, p {
            @apply text-white;
        }
    }
}

.services-section {
    .slider-list {
        min-height: 100% !important;
        .slide {
            flex: 1;
        }
    }
}